import { FILTER_NAMES } from "pages/Reports/redux/reducers/filters/hooks/constants";
import { REPORTS_FULL_PATHS } from "pages/Reports/utils";

import { APP_PATHS } from "./constants";

type RestictionsSection = {
  paths: string[];
  filters: { [key: string]: string[] };
};

type Restrictions = {
  id: string;
  enabled: RestictionsSection;
  disabled: RestictionsSection;
};

const REF_CAT_FILTERS = [
  FILTER_NAMES.REFERENCE_CATEGORY,
  FILTER_NAMES.REFERENCE_CATEGORY_AGGREGATION
];

// enabled - disables all access and allows only specified settings
// disabled - disables nothing beside specified settings
export const RESTRICTIONS: Restrictions[] = [
  {
    id: "0000082264", // [PMD-5299]: restrict "QBEV Sp. z o.o." access
    enabled: {
      paths: [
        APP_PATHS.REPORTS,
        REPORTS_FULL_PATHS.SELL_PATH,
        REPORTS_FULL_PATHS.LOGISTICS_PATH
      ],
      filters: {
        sales: [
          FILTER_NAMES.CATEGORY_1,
          FILTER_NAMES.CATEGORY_2,
          FILTER_NAMES.CATEGORY_3,
          FILTER_NAMES.BRAND,
          FILTER_NAMES.SUB_BRAND,
          FILTER_NAMES.PRODUCT,
          FILTER_NAMES.DATE,
          FILTER_NAMES.TIME_AGGREGATION,
          FILTER_NAMES.AGGREGATION
        ]
      }
    },
    disabled: {
      paths: [],
      filters: {}
    }
  },
  {
    id: "0000066760", // [PMD-5271]: restrict "Hilton" access
    enabled: {
      paths: [],
      filters: {}
    },
    disabled: {
      paths: [REPORTS_FULL_PATHS.SHARES_PATH],
      filters: {
        sales: REF_CAT_FILTERS,
        shares: REF_CAT_FILTERS,
        dynamics: REF_CAT_FILTERS,
        receipts: REF_CAT_FILTERS,
        segments: REF_CAT_FILTERS,
        hours: REF_CAT_FILTERS,
        loyalty: REF_CAT_FILTERS,
        multipack: REF_CAT_FILTERS,
        export: REF_CAT_FILTERS
      }
    }
  }
];
