import { Company, CompanyApi } from "store/reducers/userReducer";

export const standarizeCompanyData = (data: CompanyApi): Company => ({
  id: data?.id || "",
  name: data?.name || "",
  warehouseId: data?.data_warehouse_id || "",
  hasOwnAzure: data?.has_own_azure || false,
  startsOn: data?.starts_on || "",
  expiresOn: data?.expires_on || ""
});
