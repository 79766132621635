import { Fragment } from "react";
import { useSelector } from "react-redux";

import { ChartData } from "components/D3/types";
import { areTilesHiddenSelector } from "store/selectors/routerSelectors";
import { COLOR } from "utils/constants";
import { round } from "utils/round";

import { getBarHorizontalPos } from "../BarsGroup";
import { useChartScales } from "../utils";
import { useChartValueTiles } from "../utils/useChartValueTiles";
import { useDataWithChange } from "./hooks/useDataWithChange";
import { useOneLineSelection } from "./hooks/useOneLineSelected";
import { useCurrentDataset } from "./ShowDatasetSelect/hooks";

interface Props {
  lineChart: ChartData;
  height: number;
  width: number;
  originalWidth: number;
}

const TEAL = "#0d9488";

export const IncrementalChart = ({
  lineChart,
  height,
  width,
  originalWidth
}: Props) => {
  const { xScaleBand, yScale } = useChartScales(lineChart, originalWidth);
  const { both: isBothDatasets } = useCurrentDataset();
  const areTilesHidden = useSelector(areTilesHiddenSelector);
  const data = useDataWithChange(lineChart.leftChart);
  const [{ tiles }] = useChartValueTiles();

  useOneLineSelection();

  const bandWidth = xScaleBand.bandwidth();
  const regularBarWidth = Math.min(bandWidth - bandWidth * 0.2, 60);
  const barsCount = data.length;

  return (
    <g>
      {data.map((item, index) => {
        const valueY = item.valueY || 0;

        const [barX] = getBarHorizontalPos({
          barWidth: regularBarWidth,
          barIndex: index,
          barsCount,
          chartWidth: width,
          placeBarsAtCenter: false
        });
        const barY = yScale(valueY);
        const barHeight = height - barY;

        const isNegative = item.change < 0;
        const valueYLastYear = valueY - item.change;
        const changeY = isNegative ? yScale(valueYLastYear) : barY;

        const isBarInTiles = tiles.find(
          tile => tile.lineName === item.id && tile.timepoint === index
        );
        const showTile = !areTilesHidden && isBarInTiles;

        return (
          <Fragment key={`incremental-bar-${index}`}>
            <rect
              x={barX}
              y={barY}
              width={regularBarWidth}
              height={barHeight}
              fill={TEAL}
              id={item.id}
              data-multiline
            />
            <rect
              x={barX + regularBarWidth * 0.35}
              y={changeY}
              width={regularBarWidth * 0.65}
              height={height - yScale(Math.abs(item.change))}
              fill={isNegative ? COLOR.BAR_CHANGE_RED : COLOR.BAR_CHANGE_GREEN}
              id={item.id}
              data-multiline
            />
            {showTile && (
              <text
                x={barX + regularBarWidth * 0.5}
                y={(height + barY) * 0.5}
                fontSize={12}
                fill="white"
                textAnchor="middle"
                dominantBaseline="middle"
              >
                {round(valueY)}
              </text>
            )}
            {showTile && isBothDatasets && (
              <text
                x={barX + regularBarWidth}
                y={changeY - 4}
                fontSize={12}
                textAnchor="end"
                fill={isNegative ? COLOR.BAR_CHANGE_RED : TEAL}
              >
                {[
                  `${round(valueYLastYear)} `,
                  isNegative ? "(" : "(+",
                  `${round(item.change)})`
                ].join("")}
              </text>
            )}
          </Fragment>
        );
      })}
    </g>
  );
};
