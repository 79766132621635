import { ADMIN_PATHS } from "pages/Admin/utils";
import { NEWS_PATHS } from "pages/News/constants";
import { REPORTS_FULL_PATHS, SECTIONS } from "pages/Reports/utils/constants";
import {
  APP_PATHS,
  FEATURE_LOCAL_STORAGE_KEYS,
  INSIGHTS_ALLOWED_TIERS,
  USER_ROLES
} from "utils/constants";
import { Nullable, Values } from "utils/types";

export type MenuItem = {
  label: string;
  path: string;
  activePath?: string; // needed to apply styles for an active item if path is different
  users?: Values<typeof USER_ROLES>[];
  tier?: number;
  key?: string;
  badge?: string;
};

export type SecondaryMenuType = Nullable<"reports" | "news" | "admin">;

// omit logistic role as it's special role only for logistics report
const STANDARD_USER_ROLES = [
  USER_ROLES.SUPERUSER,
  USER_ROLES.CATMAN,
  USER_ROLES.ADMIN,
  USER_ROLES.ANALYST
];

export const PRIMARY_MENU_ITEMS: MenuItem[] = [
  {
    label: "Raporty",
    path: REPORTS_FULL_PATHS.SELL_PATH,
    activePath: APP_PATHS.REPORTS
  },
  {
    label: "Insights",
    path: APP_PATHS.INSIGHTS,
    users: STANDARD_USER_ROLES,
    tier: INSIGHTS_ALLOWED_TIERS[0]
  },
  {
    label: "Eksport danych",
    path: APP_PATHS.EXPORT,
    users: STANDARD_USER_ROLES
  },
  {
    label: "Baza wiedzy",
    path: APP_PATHS.KNOWLEDGE_BASE
  },
  {
    label: "Aktualności",
    path: APP_PATHS.NEWS
  }
];

export const REPORTS_MENU_ITEMS: MenuItem[] = [
  {
    label: SECTIONS.SELL,
    path: REPORTS_FULL_PATHS.SELL_PATH,
    tier: 1,
    users: STANDARD_USER_ROLES
  },
  {
    label: SECTIONS.MULTIPACK,
    path: REPORTS_FULL_PATHS.MULTIPACK_PATH,
    users: STANDARD_USER_ROLES,
    tier: 3
  },
  {
    label: SECTIONS.LOCATION,
    path: REPORTS_FULL_PATHS.LOCATION_PATH,
    users: STANDARD_USER_ROLES,
    tier: 3
  },
  {
    label: SECTIONS.SHARES,
    path: REPORTS_FULL_PATHS.SHARES_PATH,
    users: STANDARD_USER_ROLES,
    tier: 2
  },
  {
    label: SECTIONS.DYNAMICS,
    path: REPORTS_FULL_PATHS.DYNAMICS_PATH,
    users: STANDARD_USER_ROLES,
    tier: 2
  },
  {
    label: SECTIONS.RECEIPTS,
    path: REPORTS_FULL_PATHS.RECEIPTS_PATH,
    users: STANDARD_USER_ROLES,
    tier: 3
  },
  {
    label: SECTIONS.SEGMENTS,
    path: REPORTS_FULL_PATHS.SEGMENTS_PATH,
    users: STANDARD_USER_ROLES,
    tier: 1
  },
  {
    label: SECTIONS.HOURS,
    path: REPORTS_FULL_PATHS.HOURS_PATH,
    users: STANDARD_USER_ROLES,
    tier: 3
  },
  {
    label: SECTIONS.LOYALTY,
    path: REPORTS_FULL_PATHS.LOYALTY_PATH,
    users: STANDARD_USER_ROLES,
    tier: 3
  },
  {
    label: SECTIONS.LOGISTICS,
    path: REPORTS_FULL_PATHS.LOGISTICS_PATH,
    users: [...STANDARD_USER_ROLES, USER_ROLES.LOGISTIC],
    tier: 3
  },
  {
    label: SECTIONS.PROMOTIONS,
    path: REPORTS_FULL_PATHS.PROMOTIONS_PATH,
    users: STANDARD_USER_ROLES,
    tier: 3,
    key: FEATURE_LOCAL_STORAGE_KEYS.PROMOTIONS_REPORT
  }
];

export const NEWS_MENU_ITEMS: MenuItem[] = [
  {
    label: "Nowości",
    path: APP_PATHS.NEWS + NEWS_PATHS.NEWSLETTER
  }
];

export const ADMIN_MENU_ITEMS: MenuItem[] = [
  {
    label: "Zarządzanie użytkownikami",
    path: APP_PATHS.ADMIN + ADMIN_PATHS.USER_MANAGEMENT,
    users: [USER_ROLES.ADMIN, USER_ROLES.SUPERUSER]
  },
  {
    label: "Ustawienia cookies",
    path: APP_PATHS.ADMIN + ADMIN_PATHS.COOKIES_SETTINGS,
    users: [
      USER_ROLES.ADMIN,
      USER_ROLES.SUPERUSER,
      USER_ROLES.ANALYST,
      USER_ROLES.CATMAN,
      USER_ROLES.LOGISTIC
    ]
  }
];
