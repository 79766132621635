import { createSelector } from "reselect";

import { AppState } from "store";
import { RESET_DATA, resetData } from "store/actions/appActions";
import { updateQueryParams } from "store/actions/routerActions";
import { standarizeUserData } from "store/utils";
import { getLoginRedirectLocalStorage, USER_DATA, USER_ROLES } from "utils";
import { QP } from "utils/defaultQueryParams";
import { isTier, Nullable, Thunk, Tier, Values } from "utils/types";

// TYPES
export type CompanyApi = {
  id: string;
  name: string;
  data_warehouse_id: string;
  starts_on: string;
  expires_on: string;
  has_own_azure: boolean;
};

export type UserApi = {
  id: string;
  email: string;
  phone_number: string;
  company: Nullable<CompanyApi>;
  role: 0 | 1 | 2;
  is_active: boolean;
  name: Nullable<string>;
  status: 0 | 1 | 2;
  invited_at: string;
  signed_up_at: Nullable<string>;
  status_display: string;
  role_display: string;
  agreement_accepted: boolean;
  tiers_access: Tier[];
};

export type Company = {
  id: string;
  name: string;
  warehouseId: string;
  startsOn: string;
  expiresOn: string;
  hasOwnAzure: boolean;
};

export type User = {
  id: string;
  email: string;
  phoneNumber: string;
  company: Nullable<Company>;
  role: Values<typeof USER_ROLES> | null;
  isActive: boolean;
  name: string;
  status: Values<typeof USER_STATUSES> | null;
  invitedAt: string;
  signedUpAt: string;
  statusDisplay: string;
  isAgreementAccepted: boolean;
  tiersAccess: Tier[];
  currentTier: Tier;
};

type UserState = {
  user: User;
};

type UpdateUserAction = {
  type: typeof UPDATE_USER;
  payload: User;
};

export type ClearUserAction = {
  type: typeof CLEAR_USER;
  payload: User;
};

export type SetAgreementsSignedAction = {
  type: typeof SET_AGREEMENTS_SIGNED;
};

type SetCurrentTierAction = {
  type: typeof SET_CURRENT_TIER;
  payload: Tier;
};

type Actions = Values<{
  UpdateUserAction: UpdateUserAction;
  ClearUserAction: ClearUserAction;
  SetAgreementsSignedAction: SetAgreementsSignedAction;
  SetCurrentTierAction: SetCurrentTierAction;
}>;

// CONST
export const USER_STATUSES = {
  INVITED: "invited",
  ACTIVE: "active",
  DEACTIVATED: "deactivated"
} as const;

export const EMPTY_COMPANY: Company = {
  id: "",
  name: "",
  warehouseId: "",
  startsOn: "",
  expiresOn: "",
  hasOwnAzure: false
};

export const EMPTY_USER: User = {
  id: "",
  email: "",
  phoneNumber: "",
  company: EMPTY_COMPANY,
  role: null,
  isActive: false,
  name: "",
  status: null,
  invitedAt: "",
  signedUpAt: "",
  statusDisplay: "",
  isAgreementAccepted: false,
  tiersAccess: [],
  currentTier: 0
};

const UPDATE_USER = "User.UPDATE_USER" as const;
const CLEAR_USER = "User.CLEAR_USER" as const;
const SET_AGREEMENTS_SIGNED = "User.SET_AGREEMENTS_SIGNED" as const;
const SET_CURRENT_TIER = "User.SET_CURRENT_TIER" as const;

// SELECTORS
const userStateSelector = (state: AppState) => state.user || initialState;

export const userSelector = createSelector(
  userStateSelector,
  ({ user }) => user
);

export const userCompanySelector = createSelector(
  userSelector,
  ({ company }) => company
);

export const userTiersAvailableSelector = createSelector(
  userSelector,
  user => user.tiersAccess
);

export const userRoleSelector = createSelector(
  userSelector,
  user => user.role || USER_ROLES.ANALYST
);

export const isSuperUserSelector = createSelector(
  userRoleSelector,
  userRole => {
    const { SUPERUSER } = USER_ROLES;
    return userRole === SUPERUSER;
  }
);

export const isCatmanSelector = createSelector(userRoleSelector, userRole => {
  const { CATMAN } = USER_ROLES;
  return userRole === CATMAN;
});

export const isPowerUserSelector = createSelector(
  isSuperUserSelector,
  isCatmanSelector,
  (isSuperUser, isCatman) => isSuperUser || isCatman
);

export const isLogisticUserSelector = createSelector(
  userRoleSelector,
  userRole => {
    const { LOGISTIC } = USER_ROLES;
    return userRole === LOGISTIC;
  }
);

export const tierSelector = createSelector(
  userSelector,
  isPowerUserSelector,
  (user, isPowerUser): Tier => {
    return isPowerUser ? 3 : user.currentTier;
  }
);

export const isLoggedInSelector = createSelector(
  userSelector,
  ({ id }) => !!id
);

export const isAdminSelector = createSelector(userRoleSelector, userRole => {
  const { ADMIN } = USER_ROLES;
  return userRole === ADMIN;
});

export const userEmailSelector = createSelector(
  userSelector,
  ({ email }) => email
);

export const isAgreementAcceptedSelector = createSelector(
  userSelector,
  user => user.isAgreementAccepted || false
);

// ACTIONS
const updateUser = (payload: User): UpdateUserAction => ({
  type: UPDATE_USER,
  payload
});

export const setAgreementsSigned = (): SetAgreementsSignedAction => ({
  type: SET_AGREEMENTS_SIGNED
});

export const setCurrentTier = (payload: Tier): SetCurrentTierAction => ({
  type: SET_CURRENT_TIER,
  payload
});

// UTILS
export const getInitialTier = (search: string) => {
  const params = new URLSearchParams(search);
  const tier = Number(params.get(QP.TIER) || 0);
  return isTier(tier) ? tier : 0;
};

// THUNKS
export const getUser = (
  data: UserApi
): Thunk<UpdateUserAction | ReturnType<typeof setCurrentTier>> => dispatch => {
  let tier = getInitialTier(getLoginRedirectLocalStorage() || "");
  const availableTiers = data.tiers_access;

  if (tier === 0 || !availableTiers.includes(tier)) {
    tier = availableTiers[availableTiers.length - 1];
    dispatch(updateQueryParams({ [QP.TIER]: String(tier) }));
    dispatch(setCurrentTier(tier));
  }

  const userData = standarizeUserData({ user: data, currentTier: tier });
  sessionStorage.setItem(USER_DATA, JSON.stringify(userData));

  dispatch(updateUser(userData));
};

const getInitialUserState = (): User => {
  const stringifiedUserData = sessionStorage.getItem(USER_DATA);
  if (!stringifiedUserData) return EMPTY_USER;

  return JSON.parse(stringifiedUserData);
};

// REDUCER
const initialState: UserState = {
  user: getInitialUserState()
};

export const userReducer = (
  state = initialState,
  action: Actions | ReturnType<typeof resetData>
): UserState => {
  switch (action.type) {
    case UPDATE_USER:
    case CLEAR_USER:
      return { ...state, user: action.payload };

    case SET_AGREEMENTS_SIGNED:
      return {
        ...state,
        user: { ...state.user, isAgreementAccepted: true }
      };

    case SET_CURRENT_TIER:
      return {
        ...state,
        user: {
          ...state.user,
          currentTier: action.payload
        }
      };

    case RESET_DATA:
      return { user: EMPTY_USER };

    default:
      return state;
  }
};
